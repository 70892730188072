var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bs-signup-email"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"bs-heading-with-button"},[_c('bs-btn',{attrs:{"flat":"","icon-only":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('q-icon',{attrs:{"name":"app:arrow-left-slim","size":"1.5rem","color":"bs-g"}})],1),_c('h1',[_vm._v("\n        "+_vm._s(_vm.$tc('modules.signUp.labels.createAccount'))+"\n      ")])],1),_c('p',[_vm._v("\n      "+_vm._s(_vm.$tc('modules.signUp.labels.pleaseFillInInformationBelow'))+"\n    ")])]),_c('bs-form',{ref:"signUpForm",on:{"submit":_vm.emailSignUp}},[_c('bs-form-field',[_c('bs-input',{ref:"email",attrs:{"placeholder":_vm.$tc('system.labels.email'),"label":_vm.$tc('system.labels.email'),"validation":[
          function (val) { return _vm.validEmail(val) ? void 0 : { label: _vm.$tc('system.errors.invalidEmail')}; }
        ],"lazy-validation":"","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('bs-form-field',[_c('bs-input',{attrs:{"placeholder":_vm.$tc('modules.signUp.labels.confirmEmail'),"validation":[
          function (val) { return val !== _vm.email ? { ref: _vm.$refs.email, label: _vm.$tc('system.errors.fieldsMustMatch')} : void 0; }
        ],"label":_vm.$tc('modules.signUp.labels.confirmEmail'),"lazy-validation":"","required":""},model:{value:(_vm.confirmEmail),callback:function ($$v) {_vm.confirmEmail=$$v},expression:"confirmEmail"}})],1),_c('bs-form-field',[_c('bs-input',{ref:"password",attrs:{"placeholder":_vm.$tc('system.labels.password'),"label":_vm.$tc('system.labels.password'),"validation":[
          function (val) { return val.length < _vm.MODULE_CONSTANTS.USER.PASSWORDS.MIN_LENGTH ? { label: _vm.$tc('system.errors.minPasswordLength') } : void 0; }
        ],"lazy-validation":"","required":"","password":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('bs-form-field',[_c('bs-input',{attrs:{"placeholder":_vm.$tc('system.labels.password'),"label":_vm.$tc('system.labels.password'),"validation":[
          function (val) { return val !== _vm.password ? { ref: _vm.$refs.password, label: _vm.$tc('system.errors.fieldsMustMatch')} : void 0; }
        ],"lazy-validation":"","required":"","password":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('bs-checkbox',{staticClass:"bs-terms",attrs:{"required":""},model:{value:(_vm.agreeToTerms),callback:function ($$v) {_vm.agreeToTerms=$$v},expression:"agreeToTerms"}},[_c('i18n',{attrs:{"path":"modules.signUp.actions.agreeToTerms"},scopedSlots:_vm._u([{key:"termsAndConditionsSlot",fn:function(){return [_c('bs-btn',{attrs:{"text-color":"bs-g","as-link":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openTermsAndConditions($event)}}},[_vm._v("\n            "+_vm._s(_vm.$tc('modules.signUp.labels.termsAndConditions'))+"\n          ")])]},proxy:true},{key:"privacyPolicySlot",fn:function(){return [_c('bs-btn',{attrs:{"text-color":"bs-g","as-link":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openPrivacyPolicy($event)}}},[_vm._v("\n            "+_vm._s(_vm.$tc('modules.signUp.labels.privacyPolicy'))+"\n          ")])]},proxy:true}])})],1),_c('agree-to-marketing-checkbox',{staticClass:"bs-terms"}),_c('bs-btn',{staticClass:"full-width",attrs:{"type":"submit","disable":!_vm.validEmail(_vm.email) || _vm.email !== _vm.confirmEmail || !_vm.password || _vm.password.length < _vm.MODULE_CONSTANTS.USER.PASSWORDS.MIN_LENGTH || _vm.password !== _vm.confirmPassword || !_vm.agreeToTerms,"large":""}},[_vm._v("\n      "+_vm._s(_vm.$tc('system.actions.signUp'))+"\n    ")])],1),_c('already-have-an-account')],1)}
var staticRenderFns = []

export { render, staticRenderFns }